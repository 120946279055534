import React, {useState, useEffect, useRef} from 'react'
import { useQuery} from '@apollo/client';
import { GET_SINGLE_PROJECT_BY_ID,GET_SINGLE_BLOGPOST_BY_ID } from '../backend/api/queries';
import { createCleanPostData, renderWPpreformattedContent } from '../backend/api/api-functions';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Skeleton from '@mui/material/Skeleton';


export default function SinglePost() {

  // QUERY & FETCH:
  const { POST_ID } = useParams(); // id in link
  const { POST_TYPE } = useParams(); // type in link
  const { loading, error, data } = useQuery(setQueryBasedOnPostType(), { variables: { id: POST_ID } });
  const [post, setPost] = useState(null)

  // LINKS & Display:
  const location = useLocation();
  const navigate = useNavigate()
  const cachedPost = location.state?.cachedPost;
  const postTypeStringConverted = POST_TYPE === "project" ? 'Projekt' : "Beitrag"

  useEffect(()=>{
    if ( !cachedPost && data && post === null && !loading && !error && POST_TYPE){
     
      var filteredPost = createCleanPostData(data, POST_TYPE);
      setPost(filteredPost);

      updateDisplayedLink(filteredPost.title, filteredPost.id);
    } else if (cachedPost){
      setPost(cachedPost);
      updateDisplayedLink(cachedPost.title, cachedPost.id);
    } else if(!cachedPost && error){
      navigate("/404")
    }

  }, [data, post, cachedPost, loading])
  
  
  function setQueryBasedOnPostType() {
    let QUERY;

    switch (POST_TYPE) {
      case "project":
        QUERY = GET_SINGLE_PROJECT_BY_ID;
        break;
      case "blogpost":
        QUERY = GET_SINGLE_BLOGPOST_BY_ID;
        break;
      default:
        break;
    }

    return QUERY;
  }

  function updateDisplayedLink(title, id) {
    navigate(`/${POST_TYPE}/${title}/${id}`, { replace: true });
  }
  
  
  
  
  return (

        <>

        <Header stayWhite={true}/>

        {post && !loading ?

          <section className="kb_section single">


            

            <div className="container">
              
            <header className = "single" >
              <figure className = "single-thumbnail-container">
                <img className = "single-img-large" src = {post.thumbnail}></img>
              </figure>
            </header>

              <div class="kb_title">
                <span>{postTypeStringConverted}</span>
                <h3>{post.title}</h3>
              </div>

              <figcaption className = "author-description">
                <p>Publiziert am {post.date}</p>
                {post.author ? <p>Von {post.author}</p> : <></>}
              </figcaption>

              <div className="single-content">
                {renderWPpreformattedContent(post.content, "post-content")}
              </div>

              <div class="kb_button">              
                <Link to= {POST_TYPE === "project" ? '/#portfolio' : "/#blog"} className = "backlink">Zurück</Link>
              </div>

            </div>



          </section>

        :

          <section className="kb_section single">

            <header className = "single" >
              <figure className = "single-thumbnail-container">
                {/* <img className = "single-img-large" src = {post.thumbnail}></img> */}
                <Skeleton variant="rectangular" height={500} classes={{ root: "blog-skeleton-box" }} />
              </figure>
            </header>
            

            <div className="container">



              <div class="kb_title skeleton">
                {/* <span>{postTypeStringConverted}</span> */}
                <Skeleton variant="rectangular" height={50} width={80} classes={{ root: "blog-skeleton-h3" }} />
                <Skeleton variant="rectangular" height={50} width={300} classes={{ root: "blog-skeleton-h3" }} />
                <Skeleton variant="rectangular" classes={{ root: "blog-skeleton-a" }} />

                <Skeleton variant="rectangular" classes={{ root: "skeleton-text-line" }} />
                <Skeleton variant="rectangular" classes={{ root: "skeleton-text-line" }} />
                <Skeleton variant="rectangular" classes={{ root: "skeleton-text-line" }} />
                <Skeleton variant="rectangular" classes={{ root: "skeleton-text-line" }} />
                {/* <h3>{post.title}</h3> */}
              </div>

              <figcaption className = "author-description">
                {/* <p>Publiziert am {post.date}</p> */}
                {/* {post.author ? <p>Von {post.author}</p> : <></>} */}
              </figcaption>

              <div className="single-content">
                {/* {renderWPpreformattedContent(post.content, "post-content")} */}
              </div>

              <div class="kb_button">              
                {/* <Link to= {POST_TYPE === "project" ? '/#portfolio' : "/#blog"} className = "backlink">Zurück</Link> */}
              </div>  
            </div>

          </section>
    }

          <Footer />

  </>
  )
}
